.skill {
    display: flex;
    justify-content: center;
    align-items: center;
}

.skill-first {
    grid-row: 1/2;
}

.skill-second {
    grid-row: 2/2;
    grid-column: 1/4;
}

.first-line {
    height: 95%;
    width: 80%;
}

.second-line {
    height: 100%;
    width: 90%;
}

.sContainer {
    border-radius: 1rem;
    transition: 0.5s;
}

/* .sContainer:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
} */