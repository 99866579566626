.houses {
    flex-shrink: 0;
    width: 100%;
    border-radius: 0.5rem;
    display: grid;
    background-color: #A1A1A1;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
}

.divided-bg {
    background-color: #696969;
    grid-column: 6/11;
    grid-row: 1/11;
}

.house-container {
    grid-column: 2/10;
    grid-row: 2/10;
    position: relative;
    filter: grayscale(100%);
    outline: 1.1rem solid rgb(104, 81, 81);
    transition: 0.5s;
}

.img-bac {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(/public/images/Captura-de-pantalla-2023-07-15-a-la_s_-22.28.20.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.house-container:hover, .img-bac {
    filter: grayscale(0%);
    outline: 5px solid rgb(104, 81, 81);
    outline-offset: 1rem;
    cursor: pointer;
}

.text-desc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: #68645A;
    border-radius: 0.3rem;
    color: white;
    filter: grayscale(0) !important;
    font-size: 16px;
}

.title-box-2 {
    display: none;
}

.pagination-3 {
    grid-column: 10/11;
    grid-row: 7/10;
    padding: 1rem;
    width: 100%;
}

.pagination-3 p {
    font-size: 1.3rem;
    color: #BFA88D;
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

@media only screen and (max-width: 600px) {
    .houses {
        grid-template-columns: repeat(15, 1fr);
        grid-template-rows: repeat(10, 1fr);
    }

    .divided-bg {
        background-color: #696969;
        grid-column: 11/16;
        grid-row: 1/11;
    }

    .house-container {
        grid-column: 2/15;
        grid-row: 2/10;
        position: relative;
        filter: grayscale(0);
        outline: none;
        transition: 0s;
        border: 1rem solid #68645A;
        box-shadow: rgba(0, 0, 0, 0.4) 5px 5px, rgba(0, 0, 0, 0.3) 10px 10px, rgba(0, 0, 0, 0.2) 15px 15px, rgba(57, 56, 57, 0.1) 20px 20px, rgba(126, 126, 126, 0.05) 25px 25px;
    }

    .house-container:hover, .img-bac {
        outline: 0px solid rgb(104, 81, 81);
        outline-offset: 0rem;
    }

    .img-bac {
        width: 100%;
        height: 100%;
        background-image: url(/public/images/Captura-de-pantalla-2023-07-15-a-la_s_-22.28.20.webp);
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
    }

    .text-desc {
        display: none;
    }

    .pagination-3 {
        display: none;
    }

    .title-box-2 {
        position: relative;
        grid-column: 2/10;
        grid-row: 4/5;
        color: white;
        background-color: #4c473c;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title-box-2 h1 {
        padding: 0;
        margin: 0;
    }
}