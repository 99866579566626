.App {
  text-align: center;
  font-family: Lora-Regular;
  -webkit-animation: App 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: App 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  overflow: hidden;
}

.container-page {
  position: relative;
}

.max-height {
  height: 100vh;
}

.content-page {
    position: relative;
    z-index: 1;
    height: 100%;
}

.arial {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.lora-regular {
  font-family: Lora-Regular;
}

@-webkit-keyframes App {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes App {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
