::-webkit-scrollbar {
    display: none;
}

.projects {
    height: 100vh;
    background-color: #343a40;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects-container {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    gap: 1rem;
    height: 90%;
    width: 90%;
    border-radius: 0.5rem;
}

.buttonsList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
}

@media only screen and (max-width: 600px) {
    .projects-container {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        gap: 1rem;
        height: 90%;
        width: 90%;
        border-radius: 0.5rem;
    }
    
    .buttonsList   {
        display: none;
    }
}