.cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    background-color: #ebebeb;
    z-index: 2;
    transition: transform 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hide {
    transform: translateY(-100%);
}

.border-cover {
    border: 1px solid #3e3e3e34;
    height: 85%;
    width: 95%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.cover-hi {
    grid-row: 2/3;
    grid-column: 2/3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover-hi h1 {
    font-size: 4.5rem;
    overflow: visible;
    stroke: #ff0000;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
}

.cover-button {
    grid-row: 3/4;
    grid-column: 2/3;
    display: flex;
    justify-content: center;
    align-items: center;
}