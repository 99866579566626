.skills {
    /* background-color: #F3EEE7; */
    height: 100vh;
}

.skills-content {
    height: 100%;
    width: 100%;
    background-color: rgb(182, 169, 150);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

}

@media only screen and (max-width: 600px) {
    .skills {
        height: auto;
    }
    .skills-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}