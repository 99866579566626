.weather {
    height: 98%;
    background-color: #06283D;
    border-radius: 1rem;
}

.weather-container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(4,1fr);
}

.input-query {
    padding: 0.3rem;
    border-radius: 18px;
    grid-column: 2/4;
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    background-color: white;
    transition: 0.6s ease-out;
}
.input-query input {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 32px;
    border: none;
    outline: none;
}

.input-query input::placeholder {
    font-size: 20px;
    font-weight: 500;
    color: #06283D;
    text-transform: capitalize;
}

.weather-button  {
    border: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    color: #06283D;
    background: #dff6ff;
    border-radius: 50%;
    font-size: 22px;
    transition: 0.4s ease;
}

.weather-button:hover {
    color: #fff;
    background: #06283D;
}

.icon-weather {
    color: #06283D;
    font-size: 28px;
}

.weather-data {
    padding-top: 2rem;
    height: 100;
    grid-column: 2/3;
    grid-row: 2/5;
}

.weather-data img {
    border: none;
}

.temperature {
    position: relative;
    color: #fff;
    font-size: 3rem;
    font-weight: 800;
    margin-top: 1rem;
    margin-left: -16px;
}

.weather-desc {
    padding-top: 3rem;
    height: 100;
    grid-column: 3/4;
    grid-row: 2/5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.windy-desc {
    display: flex;
    flex-direction: row;
}

.windy {
    position: relative;
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
}

.title-weather {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    grid-row: 4/5;
    grid-column: 2/4;
}

@media only screen and (max-width: 600px) {
    .weather {
        margin-bottom: 1rem;
        padding: 1rem;
    }

    .weather-container {
        height: 100%;
        background-color: #06283D;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
    }

    .input-query {
        padding: 0.3rem;
        border-radius: 18px;
        grid-column: 1/5;
        margin: 1rem;
        display: flex;
        align-items: center;
        justify-content:baseline;
        background-color: white;
        transition: 0.6s ease-out;
    }
    .input-query input {
        max-width: 75%;
        font-size: 1.5rem;
        font-weight: 100;
        text-transform: uppercase;
        padding-left: 1rem;
        border: none;
        outline: none;
    }
    
    .input-query input::placeholder {
        font-size: 1,5px;
        font-weight: 500;
        color: #06283D;
        text-transform: capitalize;
    }
    
    .weather-button  {
        border: none;
        cursor: pointer;
        width: 50px;
        height: 50px;
        color: #06283D;
        background: #ffffff;
        border-radius: 50%;
        font-size: 22px;
        transition: 0.4s ease;
    }
    
    .weather-button:hover {
        color: #06283D;
        background: #ffffff;
    }
    
    .icon-weather {
        color: #06283D;
        font-size: 28px;
    }

    .weather-data {
        padding: 0;
    }

    .weather-desc {
        padding: 0;
    }
}