.frontpage__container{
    display: grid;
    grid-template-columns: 17% 43% 40%;
}

.frontpage__container > * {
    height: 100vh;
}

.color_1 {
    background-color: #D8D8D8;
}

.color_2 {
    background-color: #838383;
}

.frontpage_box_1 {
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content:baseline;
}

.frontpage_box_1 p {
    padding-top: 3rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-size: 1.3rem;
    color: #BFA88D;
}

.frontpage_box_2 {
    background-image: url("/public/images/image-vCyqxfU7S-transformed.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 0%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
}

.blackbox {
    background-color: #A1A1A1;
    grid-column: 2/5;
    grid-row: 3/6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backbox-content {
    color: #000000;
    text-align:left;
}

.blackbox-title {
    letter-spacing: 1rem;
    font-size: 42px;
    border-bottom: 1px solid #000000;
}

.blackbox-description {
    font-size: 32px;
    color: #000000;
}

.blackbox-description p {
    margin-top: 1rem;
}

.blackbox-social {
    max-width: 45%;
    display: flex;
    justify-content: space-between;
}

.contactme-description {
    max-width: auto;
}

.contactmethods {
    max-width: max-content;
}

.text-button-black {
    transition: 0.5s;
}

.text-button-black:hover{
    padding: 0.1rem 0.5rem;
    border-radius: 4px;
    color: #F3EEE7;
    cursor: pointer;
    background-color: #343a40;
}

.isClicked:hover {
    background-color: #51515100;
    color: #000000;
}

.frontpage_box_3 {
    background: #F3EEE7;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.mycover {
    grid-column: 1/4;
    margin: 1rem;
    text-align: left;
}

.mycover-title {
    font-size: 2rem;
}

.mycover-description {
    transition: 0.5s;
}

.mycover-description:hover {
    padding: 1rem;
    border-radius: 4px;
    color:#F3EEE7;
    background-color: #343a40;
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .frontpage__container {
        display: flex;
        flex-direction: column;
    }
    
    .frontpage_box_1 {
        height: 0vh;
    }

    .frontpage_box_2 {
        background-image: url("/public/images/image-vCyqxfU7S-transformed.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 0%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(5, 1fr);
    }
    
    .blackbox {
        background-color: #A1A1A1;
        grid-column: 1/5;
        grid-row: 3/6;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .frontpage_box_3 {
        height: 70vh;
        background: #F3EEE7;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    .mycover {
        grid-column: 1/4;
        grid-row: 2/3;
        margin: 1rem;
        text-align: left;
    }

    .mycover-description {
        transition: 0.5s;
        padding: 1rem;
        border-radius: 4px;
        color:#F3EEE7;
        background-color: #343a40;
    }
    
    .mycover-description:hover {
        cursor: pointer;
    }

}