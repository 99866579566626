.border-white {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.border-white:hover {
    border: 1px solid black!important;
    cursor: pointer;
}

.transition {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    transition: 0.5s;
}

.true {
    background-color: white !important;
}