.uxContainer {
    height: 100%;
    background-color: #dabb9c;
    border-radius: 1rem;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
}

.preview {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #946a40;
}

.withe-box {
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.box-configs {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.title-ux {
    grid-column: 2/3;
}

.title-ux h3 {
    margin-top: 1rem;
    margin-bottom: 0;
}

.xPosition {
    grid-row: 2/4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .uxContainer {
        margin-top: 1rem;
        padding-bottom: 1rem;
    }
    .box-configs {
        display: flex;
        flex-direction: column;
    }
}