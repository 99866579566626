.formExaple {
    border-radius: 1rem;
    height: 100%;
    background-color: white;
    background-color: #6b6250;
}

.formExample-container{
    padding: 3rem;
    padding-bottom: 1rem;
    color: white;
}

.title-form {
    background-color: #F3EEE7;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 1rem;
}

.typeSelector {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .formExample-container{
        padding: 1rem;
    }
}