.black-bg  {
    background-color: #68645A;
}

.padding-4 {
    padding: 4rem;
}

.wite-text {
    color: #ffff;
}

.font-4 {
    font-size: 1.8rem;
}

@media only screen and (max-width: 600px) {
    .black-bg  {
        height: 0vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}