.coffe {
    flex-shrink: 0;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #F3EEE7;
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    grid-template-rows: repeat(10, 1fr);
}

.image-container {
    grid-column: 10/15;
    grid-row: 2/10;
}

.coffe-img {
    height: 100%;
    background-image: url(/public/images/ssCoffe.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    border: 3rem solid #68645A;
    transition: 0.3s;
}

.coffe-img:hover {
    border: 3rem solid #4c473c;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 5px 5px, rgba(0, 0, 0, 0.3) 10px 10px, rgba(0, 0, 0, 0.2) 15px 15px, rgba(57, 56, 57, 0.1) 20px 20px, rgba(126, 126, 126, 0.05) 25px 25px;
}

.shadow-box-1 {
    background-color: #A6A79C;
    grid-column: 11/16;
    grid-row: 1/11;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.title-box-1 {
    grid-row: 2/2;
    grid-column: 6/10;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.description-box-1 {
    padding: 1rem;
    background-color: #68645A;
    grid-column: 3/9;
    grid-row: 3;
    border-radius: 0.3rem;
    color: white;
}

.description-box-1 p {
    margin: 0;
}

.pagination-2 {
    grid-column: 2/6;
    grid-row: 9;
}

.pagination-2 p {
    margin: 0;
    font-size: 1.3rem;
    color: #BFA88D;
}

@media only screen and (max-width: 600px) {
    .coffe {
        border-radius: 0.5rem;
        background-color: #F3EEE7;
        display: grid;
        grid-template-columns: repeat(15, 1fr);
        grid-template-rows: repeat(10, 1fr);
    }
    
    .image-container {
        position: relative;
        grid-column: 2/15;
        grid-row: 2/10;
        box-shadow: rgba(0, 0, 0, 0.4) 5px 5px, rgba(0, 0, 0, 0.3) 10px 10px, rgba(0, 0, 0, 0.2) 15px 15px, rgba(57, 56, 57, 0.1) 20px 20px, rgba(126, 126, 126, 0.05) 25px 25px;
    }

    .coffe-img {
        height: 100%;
        background-position: 0% 0%;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1rem solid #68645A;
        transition: 0.3s;
    }

    .coffe-img:hover {
        border: 3rem solid #4c473c;
        border: 1rem solid #68645A;
        box-shadow: none;
    }

    .title-box-1 {
        position: relative;
        color: white;
        grid-row: 4/5;
        grid-column: 2/10;
        background-color: #4c473c;
    }

    .description-box-1 {
        display: none;
    }

    .pagination-2 {
        display: none;
    }
}